#search {
  color: white;
  background-color: #5a83ff;
  width: 35px;
  height: 35px;
  border-radius: 10%;
}
#search i{
  margin: 9px;
}
#topRow{
  margin-bottom: 50px
}

.employeeHeader {
  color: #fff;
  background-color: #5173d7;
  border-color: #8dbeeb;
  border-bottom: #5173d7;
}
.employeeHeader th {
  height: 40px;
  vertical-align: inherit!important;
  padding: 0!important;
}

.employeeTbody th {
  border: 1px solid #ababab;
  vertical-align: inherit;
  font-size: 10px;
  padding: 0;
  background-color: white!important;
}
//.plusButton {
// width: 20%;
//}
#icon {
  color: #5a83ff;
}
.imageAlign {
  margin: auto;
  width: 75%;
}
#addressId {
  input {
    margin-bottom: 6px;
  }
}


