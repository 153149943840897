.scssAssetForm {

  input {
    font-size: small;
  }
  select{
    font-size: small;
  }
  nz-date-picker {
    height: 35px;
    border-radius: 4px;
    font-size: 25px;
  }
}
input[type="file"] {
  display: none;

}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 8px 12px;
  margin-top: auto;
  cursor: pointer;
  border-radius: 4px;
}

#dropdownAssetTypeButton {
  width: 200px;
  background-color: white !important;
  color: #000000 !important;
}

#SubmitId[disabled]{
  background-color: rgb(179, 179, 179);
}

.deleteFileButton{
  margin-left:10px;
  margin-bottom:3px;
}

.assetFormDropdown {
  display: flex;
  flex-direction: column;
}
