.topbar .nav-item .nav-link .img-profile{
  height: 2.5rem;
  width: 2.5rem;
}

.nav-item-logo {
  width: 100%;
  align-self: center;
  margin-left: 148px;
  p {
    text-align: center;
    font-size: 30px;
    font-family: fantasy;
    margin-bottom: 0px;
    color: #4e73df;
  }
}

.nav-bar-nav {
  width: 100%;
}

.nav-link {
  float: right;
}
