.card-body {
  margin: 0 1vw;
  padding: 2vh 1vw;
}
.projectcard{
  width: 90%;
}
.card{
  margin: 2vh 0;
}
.card p, .card h4{
  margin-bottom: 0;
}

