.noBorders{
  border:0!important;
}
.noClick{
  cursor: default!important;
}
.excelExport{
  font-size: 2em!important;
  cursor: pointer;
}
