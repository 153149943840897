#accordionSidebar {
  height: 100%;
}

.nav-link[data-toggle="collapse"].collapsed::after {
  content: none;
}

.nav-link-sidebar {
  padding-bottom: 0!important;
  span {
    font-family: monospace;
    font-size: 16px;
    color: #fafffa;
    i {
      font-size: small;
    }
  }
}

.li-company-managment {
  margin-left: 16px;
}

#collapsePagesCompany {
  .nav-item {
    margin-bottom: 12px;
  }
}

#collapsePagesAcademy {
  .nav-item {
    margin-bottom: 12px;
  }
}

#collapsePagesKPICompany {
  small {
    margin-left: 12px;
  }
}

#collapsePagesKPIAcademy {
  small {
    margin-left: 26px;
  }
}

.nav-item-sidebar {
  align-self: center;
  margin-bottom: 15px;
}

.nav-link-sidebar-container {
  align-self: center;
  text-align: center;
  .nav-item {
    margin-bottom: 15px;
  }
}

.sidebar-username {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  p {
    margin-bottom: 0;
  }
}

.sidebar-userimage {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 5px;
  img  {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 3px solid #fafffa;
  }
}

.sidebar-adminimage {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 5px;
  img  {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
}

.sidebar-label {
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  p {
    font-family: 'Marker Felt'!important;
    margin-bottom: 0;
  }
}

.sidebar-brand-text {
  border: 1px solid #4f4ff1;
  border-radius: 50%;
  width: 60px;
  background-color: white;
  height: 60px;
  img {
    border-radius: 50%;
    margin-top: 4px;
  }
}

.sidebar-heading {
  text-align: center!important;
  margin-bottom: 15px;
  margin-top: 15px;
}

.sidebar {
  max-width: 200 !important;

  p {
    font-family: cursive;
    font-size: 17px;
  }

}

.env-color-test {
  background-color: #ff7b00 !important;
}

.env-color-collaudo {
  background-color: #ffe605 !important;
}

.env-color-stage {
  background-color: #008000 !important;
}
