.projectCollapse #accordionExample {
  width: 100%;
}

.projectCollapse #headingOne {
  background-color: #4a74e3;
}

.newProjectButton{
  font-size: 14px!important;
}
.newProjectButton #icon{
    vertical-align: middle;
    color: #28a745;
}
.newProjectButton:hover #icon{
  vertical-align: middle;
  color: white;
}

.modalTable td, .modalTable th {
  border-bottom: 1px solid #8f8e8e;
  text-align: left;
}

.headerModal {
  color: #fff;
  background-color: #5173d7;
  border-color: #8dbeeb;
  border-bottom: #5173d7;
}

.fa-plus{
  color: #05a405;
  cursor: pointer;
}
.fa-minus {
  color: darkred;
  cursor: pointer;
}
