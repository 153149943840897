a.disabled-link {
  pointer-events: none;
  cursor: default;
}
.deleteIcon{
  color: #b31a1a;
  padding: 4px
}

.card-header {
  background-color: #4e73df!important;
  .font-weight-bold {
    color: white!important;
  }
}
