.headerTimesheet {
  color: #fff;
  background-color: #4e73df;
  border-color: #8dbeeb;
  border-bottom: #5173d7;
  border-top-left-radius: 30% !important;
  border-top-right-radius: 30% !important;
  height: 40px;
}

.headerTimesheet th {
  padding: 0 !important;
  font-family: monospace;
}

th.border-time div {
  height: 22px;
  width: 1px;
  margin-top: -22px;
  background: white;
}

.totalSide-base {
  label {
    color: black;
  }
}

.base {
  label {
    color: white;
  }
}

.limit-over {
  background-color: #ce1919;

  label {
    color: white;
  }
}

.normal-limit {
  background-color: #228b22;

  label {

    color: white;
  }
}

.under-limit {
  background-color: rgb(202, 123, 4);

  label {

    color: white;
  }
}

.dateMonthTimesheet {
  text-transform: capitalize !important;
}

td.border-body div {
  height: 22px;
  width: 1px;
  margin-top: -31px;
  background: white;
}

.week-button-container {
  text-align: end;
  width: 95%;
}

.form-timesheet {
  justify-content: center;
  display: flex;
}

table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}

table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}

table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}

.table-timesheet {
  width: 90% !important;
}

.table-timesheet td, .table-timesheet th {
  text-align: center;
  vertical-align: middle !important;
}

.body-timesheet {
  background-color: white;
}

.body-timesheet tr td {
  font-family: monospace;
}

.body-timesheet tr {
  border: 1px solid #a7a6a6;
}

.table-footer {
  background-color: #4e73df;
  border: none !important;
  height: 40px;
}

.table-footer {
  b {
    color: white;
  }
}


.rowColor {
  background-color: rgb(219, 219, 219);
}

.weekendRowColor {
  background-color: rgb(216, 164, 164);
}

.assignedHourCell {
  background-color: rgb(193, 216, 164);
}

.weekendTextColor {
  color: rgb(196, 3, 3);
}

#daysBox {
  padding: 0;
  width: 70px;
  height: 50px;
}

#inputBox {
  label {
    width: 70px !important;
  }

  input {
    text-align: center;
    width: 70% !important;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

}

#dropdownMenuButton:focus {
  outline: none !important;
}

#dropdownMenuButton {
  width: 200px;
  background-color: #2b4fc3 !important;
  color: white !important;
}

.backButton {
  background-color: #5173d7 !important;
  color: white !important;
  border-bottom-right-radius: 0% !important;
  border-top-right-radius: 0% !important;
  margin-right: 1px;
  background-color: #4e73df !important;
}

.nextButton {
  background-color: #5173d7 !important;
  color: white !important;
  border-bottom-left-radius: 0% !important;
  border-top-left-radius: 0% !important;
  background-color: #4e73df !important;
}

.exportButton {
  background-color: #2b4fc3 !important;
  color: white !important;
  border-radius: 5px;

  .form-label {
    cursor: pointer;
  }
}

.exportButtonSmallerFont{
  font-size: 14px!important;
}

.precompilaButton {
  background-color: #e26b0d !important;
  color: white !important;
}

.precompileOption-title {
  p {
    color: rgb(0, 0, 0);
    align-self: center;
    margin-bottom: 0;
    font-size: large;
    margin-bottom: 5px;
  }
}

.precompileOption-container {
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: #4e73df;
  cursor: pointer;
}

.precompileOption {
  border: none;
  color: white !important;
}

.confermaButton {
  background-color: forestgreen !important;
  color: white !important;
}

.warning {
  padding: 0 0 10rem 0;
  color: #c70c0c;
}

.saveButton {
  background-color: #ad1d1d !important;
  color: white !important;
  margin-right: 20px;
}

.redTime {
  color: red;
  font-weight: bold;
}

#custom-card {
  border: solid 2px;
  border-radius: 10px;
  height: 95px;
}

#custom-b {
  font-weight: 500;
  color: #4e73df;
}

.w-30{
  width: 30%;
}
