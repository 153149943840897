
.profileCollapse #headingOne {
background-color: #4a74e3;
}
.profileCollapse #accordionExample {
  width: 60%;
}

.profileCard #cardHeader {
  background-color: #4a74e3;
}

.profileCard #cardInfo {
  color: white!important;
}
.scssProfile {
  .modal-header {
    background-color: #4a74e3;
    h5 {
      color: white;
    }
  }
}
#exampleModalLabel {
  color: white;
}
.scssProfile {
  .viewport figure img {
    position: absolute;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
}
.scssProfile {
  .viewport:hover figure img {
    left: 0;
    top: 0;
    background-color: rgba(55, 55, 57, 0.5);
  }
}
.scssProfile {
  .viewport {
    height: 100px;
    margin-right: 20px;
    position: relative;
    width: 100px;
    border: 2px solid #fff;
    border-radius: 3px;
  }
}
.scssProfile {
  figcaption {
    color: white;
    font-family: Calibri;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    text-shadow: 0 0 3px black;
    top: 0;
    right: 0;
    z-index: 200;
    transition: all 0.2s ease-in-out;
  }
}
.scssProfile {
  .viewport:hover figure figcaption {
    opacity: 1;
  }
}
