.employeeAssignmentHeader {
  color: #fff;
  background-color: #5173d7;
  height: 30px!important;
}
.employeeAssignmentTable .thNome{
  border-radius: 6px 0px 0px 0px;
}

.employeeAssignmentTable .thData{
  border-radius: 0px 6px 0px 0px;
}
.employeeAssignmentTable {
  border: none;

}

.employeeAssignmentTable th {
  width: 6%!important;
  padding: 0!important;
  height: 40px;
}

.employeeAssignmentTbody td {
  border: 1px solid #c1bfbf;
}
.employeeAssignmentTable th, .employeeAssignmentTable td {
border-bottom: 1px solid #b8b7b7;
border-top: none;
text-align: center;
}
.modaleCla{
  position: absolute;
  background-color: rgba(234, 224, 224, 0.8);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width:20%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.modaleCla p {
  padding: 0 10%;
  cursor: pointer;
}

.projAssInput{
  max-width: 90%;
}
.thDataProjAss{
  border-top-right-radius: 0!important;
}
.thProjAss{
  border-top-right-radius: 6px;
}
.fa-times{
  cursor: pointer;
}
.cancelEmployee{
  font-size: 1.5em;
}
.assignment-input{
  .mat-form-field{
    width: 8em;
  }
}
