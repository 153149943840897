 #adminTimesheetSearchable .form-control{
   width: 300px;
   margin-right: 15px;

}




 #dropdownRow {
   margin-left: 10px;
   align-items: center;
 }

 .wrongValue {
  border-color: red!important;
 }

 .rowColor {
  background-color: rgb(219, 219, 219);
}

.adminWeekendTextColor {
  color: rgb(214, 75, 75);
}

.headerAdminTimesheet {
  color: #fff;
  background-color: #5173d7;
  border-color: #8dbeeb;
  border-bottom: #5173d7;
}
 .headerAdminTimesheet th {
   width: 2%!important;
   padding: 0!important;
 }



 #dropdownProjectButton {
   width: 200px;
   background-color: #5173d7!important;
   color: white!important;
 }
 #exportAllButton{
   font-size: 12px;
   width: 100px;
   background-color: #5173d7!important;
   color: white!important;
 }
 .timeSheetTable{
   margin-top: 0;
 }
.adminTimesheetTitle{
  color:#fff;
  margin-top: 30px;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  background-color: #5173d7;
}
.shadowArrows{
  cursor: pointer;
}
.table td{
  padding: 0!important;
}
 .redDate{
   height:100%;
   width: 100%;
   display: block;
    background-color: indianred;
    color: #fff;
}

 .modaleCla{
   position: absolute;
   background-color: rgba(234, 224, 224, 0.8);
   display: flex;
   flex-direction: column;
   align-items: baseline;
   width:100%;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
 }
 .modaleCla p {
   padding: 0 10%;
   cursor: pointer;
 }

 .ics{
   font-weight: bold;
 }
.inputTimesheetAdmin{
  -webkit-appearance: none!important;
  -moz-appearance: textfield!important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  margin: 1px;
  width: 100%;
  border:1px solid rgba(0,0,0,0.5);
  border-radius: 2px;
}

.red{
  color: red;
}
