.scssPasswordForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.scssPasswordForm {
  .form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
  }
}
.scssPasswordForm {
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
}
.scssPasswordForm{
  .buttonconferma {
    text-align: center;
    margin-top: 25px;
  }
}
