.collapsebutton{
  color: white!important;
}
.sandwichIcon{
  font-size: 20px!important;
  color: white!important;
  margin-top: 10px!important;
}

.accordion>.card {
  overflow: visible !important;
}
