.nav-tab {
  margin-right: 2px;
}

.nav-tab-button {
  border: 1px solid #4e73df;
  box-shadow: -4px 1px 4px #adabab;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 38px;
  min-width: 80px;
  background-color: #4e73df;
  color: white;
  opacity: 0.4;
}
.nav-tab-button:hover{
  border: 1px solid #4e73df;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 38px;
  min-width: 80px;
  background-color: #4e73df;
  color: white;
  opacity: 9;
}

.nav-tab-button.selected{
  border: 1px solid #4e73df;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 38px;
  min-width: 80px;
  background-color: #4e73df;
  color: white;
  opacity: 9;
}

.nav-tab-button:target,
.nav-tab-button:focus {
  border: 1px solid #4e73df;
  outline: none;
  box-shadow: #4e73df;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 38px;
  min-width: 80px;
  background-color: #4e73df;
  color: white;
  opacity: 9;
}
.nav-tabs {
  width: 100%;
}
.nav-tabs li:last-child {
  float: right;
}
.nav-tab-close {
  width: 45px;
  margin-left: auto;
}

.menu {
  p {
    font-size: 25px;
    width: 122px;
    background-color: #4e73df;
    color: white;
    border-radius: 10px;
    box-shadow: -2px 5px 3px #adabab;
  }
  img {
    width: 25px;
    margin-bottom: 7px;
    margin-left: 7px;
    margin-right: 5px;
  }
  b {
  text-shadow: -2px 3px 2px #3c393b;
  cursor: default;
  }
}

.nav-tab-close-button {
  border: 1px solid #971414;
  box-shadow: -4px 1px 4px #adabab;
  outline: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 38px;
  width: 45px;
  background-color: #971414;
  color: white;
  opacity: 0.4;
  i {
    font-size: x-large;
  }
}

.nav-tab-close-button:focus {
  border: 1px solid #971414;
  outline: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 38px;
  width: 45px;
  background-color: #971414;
  color: white;
  opacity: 0.4;
  i {
    font-size: x-large;
  }
}
.nav-tab-close-button:hover {
  border: 1px solid #971414;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 38px;
  width: 45px;
  background-color: #971414;
  color: white;
  opacity: 9;
}

.nav-tab-container {
  border-top: 1px solid #7997f1;
  border-right: 2px solid #4e73df;
  border-bottom: 2px solid #4e73df;
  border-left: 2px solid #4e73df;
  box-shadow: -4px 5px 4px #adabab;
}

.scssEmployeeForm {
  input {
    font-size: small;
  }
  select {
    font-size: small;
  }
  nz-date-picker {
    height: 35px;
    border-radius: 4px;
    font-size: 25px;
  }
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 8px 12px;
  margin-top: auto;
  cursor: pointer;
  border-radius: 4px;
}

.fileDownload {
  background-color: rgb(96, 170, 67);
  color: rgb(238, 237, 237);
  display: inline-block;
  margin-top: auto;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  padding: 0;
}
.employeeFile {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.employeeFile:hover .fa-file-pdf-o {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.downloadButtonAbled {
  border: 1px solid #ccc;
  background-color: rgb(96, 170, 67);
  color: white;
  display: inline-block;
  padding: 8px 12px;
  margin-top: auto;
  border-radius: 4px;
  cursor: none;
  margin-left: 10px;
}
.downloadButtonAbled[disabled] {
  background-color: rgb(179, 179, 179);
}

#addressId {
  input {
    margin-bottom: 6px;
  }
}

#SubmitId[disabled] {
  background-color: rgb(179, 179, 179);
}

.deleteFileButton {
  margin-left: 10px;
  margin-bottom: 3px;
}
.fileHandler {
  display: flex;
  align-items: center;
}
.employeeAssignment {
  padding: 2em;
  border-radius: 15px;
  background-color: rgba(155, 184, 250, 0.1);
}
.fa-upload {
  position: absolute;
  font-size: 1.8em !important;
  color: black;
  cursor: pointer;
}

.fa-file-pdf-o {
  font-size: 2em !important;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 5px;
  color: #920e34;
  cursor: pointer;
}
.employeeFormInfo {
  background-color: rgba(155, 184, 250, 0.1);
  border-radius: 10px;
  padding: 0.3rem;
}
.employeeProjectShow {
  padding-bottom: 0.1em;
  margin-top: 0.4em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
