.dateBody {
    text-transform: capitalize!important;
    color: black;
}

.checktrue {
    color: #54b418;
    font-size: x-large!important;
}

.checkfalse {
    color: #d51212;
    font-size: x-large!important;
}