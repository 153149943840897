.titleAndDelete {
  display: flex;
  justify-content: flex-end;
}

.mat-header-row {
  background-color: #4e73df;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
