.error-container {
    display: block;
    margin-top: 15px;
}

.error-container {
    .projAssInput:focus {
        color: #495057;
        background-color: #fff;
        border-color: rgb(194, 9, 9);
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(194 9 9 / 25%);
    }
}
.error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}