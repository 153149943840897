.scssForgotPasswordForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.scssForgotPasswordForm {
  .form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
  }
}
.scssForgotPasswordForm {
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
}
.scssForgotPasswordForm{
  .buttonconferma {
    text-align: center;
    margin-top: 25px;
  }
.btn-primary:disabled{
  border-color: white;
}
}
