.newAssetButton{
  font-size: 14px!important;
}
.newAssetButton #icon{
  color: #28a745;
}
.newAssetButton:hover #icon{
  color: white;
}

.mat-sort-header-content{
  color: white;
}

.mat-header-cell{
  color: white;
}
