.scssForgotPassword {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

}

.scssForgotPassword {
  #emailId {
    width: 370px;
  }
  input {
    width: 100%;
    height: 50px;
    margin-top: 5px;
  }
  #email {
    padding-top: 4px;
  }
}
