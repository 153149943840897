.courses-profit-projection-table {
    margin-left: 86px;
    margin-right: 86px;
}

.courses-profit-projection-title {
    margin-top: 54px;
    margin-bottom: 54px;
    b {
        font-size: 36px;
    }
}

.course-profit-projection-slider {
  margin-left: 86px;
  margin-right: 86px;
  margin-bottom: 35px;
 
  h2 {
    align-self: center;
    font-size: 24px;
  }

  .mat-card-body {
    justify-content: space-between;
  }

  .mat-slider-thumb {
    background-color: #4e73df;
}

.mat-accent .mat-slider-track-fill {
  background-color: #4e73df;
  ;
}
  .slider-input {
    align-self: center;
    width: 30px;
    input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
  }
}

.real-tot-course-profit-projection {
  display: grid;

  label {
    font-size: 24px;
    font-weight: bold;
  }
.span-input {

  border: 2px solid black;
  font-weight: bold;
  font-size: 36px;
  padding-right: 30px;

  input {
    width: 270px;
    height: 121px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    border: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
}

.summary-title {
  margin-left: 86px;
}

.summary-input {
  margin-left: 86px;
  margin-right: 86px;
  margin-bottom: 5px;
  label {
    font-size: 20px;
    font-weight: bold;
    margin-right: 20px;
  }
  input {
    border: none;
    font-size: 20px;
    background-color: white;
    align-self: baseline;
  }
}

.virtual-tot-course-profit-projection {
  display: grid;

  label {
    font-size: 24px;
    font-weight: bold;
  }
  .span-input {

    border: 2px solid black;
    font-size: 36px;
    font-weight: bold;
    padding-right: 30px;
  
  input {
    width: 270px;
    height: 121px;
    font-size: 36px;
    border: none;
    font-weight: bold;
    text-align: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  }
}

.good-projection {
  color: green;
}

.bad-projection {
  color: #9f0a0a;
}

.total-container {
  margin-left: 86px;
  margin-right: 86px;
  justify-content: space-between;
}

.example-h2 {
    margin: 0 8px 16px;
  }
  
  .example-section {
    display: flex;
    align-content: center;
    align-items: center;
    height: 60px;
  }
  
  .example-margin {
    margin: 8px;
  }
  
  .mat-slider-horizontal {
    width: 300px;
  }
  
  .mat-slider-vertical {
    height: 300px;
  }
  
  .mat-card + .mat-card {
    margin-top: 8px;
  }
  
  .example-result-card h2 {
    margin: 0 8px;
  }
  
  .example-result-card .example-label-container.example-label-horizontal {
    display: flex;
    justify-content: space-between;
    margin: 0 0 12px 16px;
    max-width: 284px;
  }
  
  .example-result-card .example-label-container.example-label-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 68px;
    height: 48px;
  }
  
  .example-result-card .example-value-label {
    font-weight: 600;
  }
  