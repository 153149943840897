.dashboard {
  padding: 2vh 3vw;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;

  .hp-title {
    text-align: center;
    margin-top: 100px;

    b {
      color: #000000e6;
      font-size: 200px;
      opacity: 0.9;
      text-shadow: 2px 0 0 #d28c8c, -2px 0 0 #4a6fdc, 0 2px 0 #c0c6d8, 0 -2px 0 #4a6fdc, 1px 1px #4a6fdc, -1px -1px 0 #4a6fdc, 1px -1px 0 #4a6fdc, -1px 1px 0 #4a6fdc;
    }
  }
}

