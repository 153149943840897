.modal-option-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #e3e6f0;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    align-items: center;
  }

  .exitButton {
      background-color: #971414!important;
      color: white!important;
      i {
        font-size: large;
      }
  }

  .optionButton{
    margin: 1%;
    max-width: max-content;
    min-width: 10em;
  }
