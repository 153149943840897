/* You can add global styles to this file, and also import other style files */

@import "theme/original/original.css";
//core
@import "theme/core/_sidebar.scss";
@import "theme/core/layout.scss";
@import "theme/core/footer.scss";
@import "theme/core/_topbar.scss";

//features
@import "theme/features/components/_dashboard.scss";
@import "theme/features/components/_login.scss";
@import "theme/features/components/_profilecard.scss";
@import "theme/features/components/_projectcard.scss";
@import "theme/features/components/_timesheet.scss";
@import "theme/features/components/_profile.scss";
@import "theme/features/components/_employeesmanagement.scss";
@import "theme/features/components/_projectsmanagement.scss";
@import "theme/features/components/_admintimesheet.scss";
@import "theme/features/components/_employeeform.scss";
@import "theme/features/components/_passwordForm.scss";
@import "theme/features/components/_forgotpassword.scss";
@import "theme/features/components/_projectform.scss";
@import "theme/features/components/_forgotpasswordform.scss";
@import "theme/features/components/_createClientForm.scss";
@import "theme/features/components/_employeeAssignmentToProject.scss";
@import "theme/features/components/_assetform.scss";
@import "theme/features/components/_assetmanagement.scss";
@import "theme/features/components/_invoiceform.scss";
@import "theme/features/components/_kpimanagement.scss";
@import "theme/features/components/_estrattoContoTableComponent.scss";
@import "theme/features/components/_fileHandler.scss";
@import "theme/features/components/_projectView.scss";
@import "theme/features/components/_downloadView.scss";
@import "theme/features/components/_managersmanagment.scss";
@import "theme/features/components/_payslipsmanagement.scss";
@import "theme/features/components/_admintimesheetdetail.scss";
@import "theme/features/components/_representatives.scss";
@import "theme/features/components/_historycourses.scss";
@import "theme/features/components/_coursesprofitprojection.scss";


//shared
@import "theme/shared/loading.scss";
@import "theme/shared/basiccard.scss";
@import "theme/shared/collapsecard";
@import "theme/shared/_modal.scss";
@import "theme/shared/_modal-option.scss";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

.thead-table {
    thead {
      background-color: #4e73df;
    }
    h6, .mat-header-cell, .mat-sort-header-content {
      color: white;
    }
  }

.mat-elevation-z2 {
  border-radius: 10px;
  thead {
    background-color: #4e73df;
    color: white;
  }
  h6 {
    color: white;
  }
}
