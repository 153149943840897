.kpiFilters{
  margin: 50px 50px;
  padding:3% 5% ;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.5);
  border-radius: 15px;
  background-color: #4e73df;
  display: flex;
  justify-content: space-between;
  width: 75%;
}

.kpiButtons{
  padding: 2% 5%;
  border-left: 1px solid rgba(255,255,255,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggleButton{
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .3rem 0;
  font-size: 0.85rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

