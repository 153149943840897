.scssLogin{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.scssLogin {
  .form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
  }
}
.scssLogin {
  .form-label-group {
    position: relative;
    margin-bottom: 1rem;
  }
}
.scssLogin {
  .form-label-group > input,
  .form-label-group > label {
    height: 3.125rem;
    padding: 0.75rem;
  }
}
.scssLogin {
  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
  }
}
.scssLogin {
  .form-label-group input::-webkit-input-placeholder {
    color: transparent;
  }
}
.scssLogin {
  .form-label-group input:-ms-input-placeholder {
    color: transparent;
  }
}
.scssLogin {
  .form-label-group input::-ms-input-placeholder {
    color: transparent;
  }
}
.scssLogin {
  .form-label-group input::-moz-placeholder {
    color: transparent;
  }
}
.scssLogin {
  .form-label-group input::placeholder {
    color: transparent;
  }
}
.scssLogin {
  .form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: 0.25rem;
  }
}
.scssLogin {
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 12px;
    color: #777;
  }
}




