.history-courses-title {
    margin-top: 54px;
    margin-bottom: 54px;
    b {
        font-size: 36px;
    }
}

.history-courses-table {
    margin-left: 86px;
    margin-right: 86px;
}

.history-courses-export-button {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 30px;
}

.null-table-title {
    text-align: center;
    font-size: 24px;
}

.exportButton {
    background-color: #2b4fc3!important;
    color: white!important;
    border-radius: 5px;
    .form-label {
      cursor: pointer;
    }
  }